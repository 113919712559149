import Head from "next/head";
import { LayoutData } from "@types";
import Script from "next/script";
import 'sentry.client.config'
import { CardsSectionProps } from "../components/shared/CardsList/types";
import { useEffect, useState } from "react";
import axios from "axios";
import CategoryList from "../components/shared/CardsList";
import Hero from "../components/shared/Hero";
import { HeroProps } from "../components/shared/Hero/types";
import OverviewCardsHome from "../components/shared/OverviewCardsHome";
import { SimplySellMoreProps } from "../components/shared/SimplySellMore/types";
import SimplySellMore from "../components/shared/SimplySellMore";
import CustomCarousel, { CarouselProps } from "../components/shared/CustomCarousel";
import { RequestProductProps } from "../components/shared/RequestProduct/types";
import RequestProduct from "../components/shared/RequestProduct";
import PromotionHomePageBanner from "../components/shared/PromotionHomePageBanner";
import { getResourceId } from "../utilities/mapHubspotModules";
type HomeProps = {
  layoutData: LayoutData;
};

export default function Home({ layoutData }: HomeProps) {
  const [hubspotFieldsCardSection1, setHubspotFieldsCardSection1] = useState<CardsSectionProps>();
  const [hubspotFieldsCardSection2, setHubspotFieldsCardSection2] = useState<CardsSectionProps>();
  const [hubspotFieldsCardSection3, setHubspotFieldsCardSection3] = useState<CardsSectionProps>();
  const [hubspotFieldsCardSection4, setHubspotFieldsCardSection4] = useState<CardsSectionProps>();
  const [hubspotFieldsCardSection5, setHubspotFieldsCardSection5] = useState<CardsSectionProps>();
  const [hubspotFieldsHero, setHubspotFieldsHero] = useState<HeroProps>();
  const [hubspotFieldsSimplySellMore, setHubspotFieldsSimplySellMore] = useState<SimplySellMoreProps>();
  const [hubspotFieldsCarousel1, setHubspotFieldsCarousel1] = useState<CarouselProps>();
  const [hubspotFieldsCarousel2, setHubspotFieldsCarousel2] = useState<CarouselProps>();
  const [hubspotFieldsCarousel3, setHubspotFieldsCarousel3] = useState<CarouselProps>();
  const [hubspotFieldsCarousel4, setHubspotFieldsCarousel4] = useState<CarouselProps>();
  const [hubspotFieldsCarousel5, setHubspotFieldsCarousel5] = useState<CarouselProps>();
  const [hubspotFieldsRequestProduct, setHubspotFieldsRequestProduct] = useState<RequestProductProps>();

  const [isLoading, setIsLoading] = useState(true);
 
  useEffect(() => {
    const fetchHubspotData = async () => {
      try {
        const { data } = await axios.get(`/api/hubspot-cms/cardSection-cms?rowId=${getResourceId("rows", "cardSectionWelcomeBack")}`);
        setHubspotFieldsCardSection1(data.fieldPlaceholders);
        setIsLoading(false);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error("Error fetching HubSpot Carrosel data :", error.message);
        } else {
          console.error("Error fetching HubSpot Carrosel data:", error);
        }
        setIsLoading(false);
      }
    };

    fetchHubspotData();
  
}, []);

useEffect(() => {
  const fetchHubspotData = async () => {
    try {
      const { data } = await axios.get(`/api/hubspot-cms/cardSection-cms?rowId=${getResourceId("rows", "cardSectionTopCategories")}`);
      setHubspotFieldsCardSection2(data.fieldPlaceholders);
      setIsLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error fetching HubSpot Carrosel data :", error.message);
      } else {
        console.error("Error fetching HubSpot Carrosel data:", error);
      }
      setIsLoading(false);
    }
  };

  fetchHubspotData();

}, []);

useEffect(() => {
  const fetchHubspotData = async () => {
    try {
      const { data } = await axios.get(`/api/hubspot-cms/cardSection-cms?rowId=${getResourceId("rows", "cardSectionShopByBrands1")}`);
      setHubspotFieldsCardSection3(data.fieldPlaceholders);
      setIsLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error fetching HubSpot Carrosel data :", error.message);
      } else {
        console.error("Error fetching HubSpot Carrosel data:", error);
      }
      setIsLoading(false);
    }
  };

  fetchHubspotData();

}, []);

useEffect(() => {
  const fetchHubspotData = async () => {
    try {
      const { data } = await axios.get(`/api/hubspot-cms/cardSection-cms?rowId=${getResourceId("rows", "cardSectionShopByBrands2")}`);
      setHubspotFieldsCardSection4(data.fieldPlaceholders);
      setIsLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error fetching HubSpot Carrosel data :", error.message);
      } else {
        console.error("Error fetching HubSpot Carrosel data:", error);
      }
      setIsLoading(false);
    }
  };

  fetchHubspotData();

}, []);

useEffect(() => {
  const fetchHubspotData = async () => {
    try {
      const { data } = await axios.get(`/api/hubspot-cms/cardSection-cms?rowId=${getResourceId("rows", "cardSectionShopByBusiness")}`);
      setHubspotFieldsCardSection5(data.fieldPlaceholders);
      setIsLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error fetching HubSpot Carrosel data :", error.message);
      } else {
        console.error("Error fetching HubSpot Carrosel data:", error);
      }
      setIsLoading(false);
    }
  };

  fetchHubspotData();

}, []);

useEffect(() => {
  const fetchHubspotData = async () => {
    try {
      const { data } = await axios.get("/api/hubspot-cms/hero-cms");
      setHubspotFieldsHero(data.fieldPlaceholders);
      setIsLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error fetching HubSpot Carrosel data :", error.message);
      } else {
        console.error("Error fetching HubSpot Carrosel data:", error);
      }
      setIsLoading(false);
    }
  };

  fetchHubspotData();

}, []);

useEffect(() => {
  const fetchHubspotData = async () => {
    try {
      const { data } = await axios.get("/api/hubspot-cms/homeSymplySellMore-cms");
      setHubspotFieldsSimplySellMore(data.fieldPlaceholders);
      setIsLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error fetching HubSpot Carrosel data :", error.message);
      } else {
        console.error("Error fetching HubSpot Carrosel data:", error);
      }
      setIsLoading(false);
    }
  };

  fetchHubspotData();

}, []);

useEffect(() => {
  const fetchHubspotData = async () => {
    try {
      const { data } = await axios.get(`/api/hubspot-cms/carrousel-cms?rowId=${getResourceId("rows", "carouselFeaturedProductsHome")}`);
      setHubspotFieldsCarousel1(data.fieldPlaceholders);
      setIsLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error fetching HubSpot Carrosel data :", error.message);
      } else {
        console.error("Error fetching HubSpot Carrosel data:", error);
      }
      setIsLoading(false);
    }
  };

  fetchHubspotData();

}, []);

useEffect(() => {
  const fetchHubspotData = async () => {
    try {
      const { data } = await axios.get(`/api/hubspot-cms/carrousel-cms?rowId=${getResourceId("rows", "carouselHaggleProducts")}`);
      setHubspotFieldsCarousel2(data.fieldPlaceholders);
      setIsLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error fetching HubSpot Carrosel data :", error.message);
      } else {
        console.error("Error fetching HubSpot Carrosel data:", error);
      }
      setIsLoading(false);
    }
  };

  fetchHubspotData();

}, []);

useEffect(() => {
  const fetchHubspotData = async () => {
    try {
      const { data } = await axios.get(`/api/hubspot-cms/carrousel-cms?rowId=${getResourceId("rows", "CarouselPopularThisMonth")}`);
      setHubspotFieldsCarousel3(data.fieldPlaceholders);
      setIsLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error fetching HubSpot Carrosel data :", error.message);
      } else {
        console.error("Error fetching HubSpot Carrosel data:", error);
      }
      setIsLoading(false);
    }
  };

  fetchHubspotData();

}, []);

useEffect(() => {
  const fetchHubspotData = async () => {
    try {
      const { data } = await axios.get(`/api/hubspot-cms/carrousel-cms?rowId=${getResourceId("rows", "CarouselFeaturedProduct2")}`);
      setHubspotFieldsCarousel4(data.fieldPlaceholders);
      setIsLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error fetching HubSpot Carrosel data :", error.message);
      } else {
        console.error("Error fetching HubSpot Carrosel data:", error);
      }
      setIsLoading(false);
    }
  };

  fetchHubspotData();

}, []);

useEffect(() => {
  const fetchHubspotData = async () => {
    try {
      const { data } = await axios.get(`/api/hubspot-cms/carrousel-cms?rowId=${getResourceId("rows", "CarouselRecentlyViewed")}`);
      setHubspotFieldsCarousel5(data.fieldPlaceholders);
      setIsLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error fetching HubSpot Carrosel data :", error.message);
      } else {
        console.error("Error fetching HubSpot Carrosel data:", error);
      }
      setIsLoading(false);
    }
  };

  fetchHubspotData();

}, []);

useEffect(() => {
  const fetchHubspotData = async () => {
    try {
      const { data } = await axios.get("/api/hubspot-cms/requestProduct-cms");
      setHubspotFieldsRequestProduct(data.fieldPlaceholders);
      setIsLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error fetching HubSpot Carrosel data :", error.message);
      } else {
        console.error("Error fetching HubSpot Carrosel data:", error);
      }
      setIsLoading(false);
    }
  };

  fetchHubspotData();

}, []);

  return (
    <>
      <Head>
        <title>Epallet</title>
        <meta
          name="description"
          content="Shop at EPallet for the widest selection and best variety."
        />
        <meta name="viewport" content="width=device-width" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        {/* <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet" /> */}
        <Script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer',${process.env.NEXT_PUBLIC_GTM_ID});
          `}
        </Script>
      </Head>
      {/* <body>
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
      </body> */}
      <main style={{ padding: 0 }} role="main">
        {/*<DynamicLayout data={layoutData} />  */}
        {hubspotFieldsCardSection1 && <CategoryList {...hubspotFieldsCardSection1} />}
        {hubspotFieldsHero && hubspotFieldsHero.image && <Hero {...hubspotFieldsHero} />}
        <OverviewCardsHome />
        {hubspotFieldsCardSection2 && <CategoryList {...hubspotFieldsCardSection2} />}
        {hubspotFieldsSimplySellMore && hubspotFieldsSimplySellMore.image && <SimplySellMore {...hubspotFieldsSimplySellMore} />}
        {hubspotFieldsCarousel1 && <CustomCarousel {...hubspotFieldsCarousel1} />}
        {/* {hubspotFieldsCarousel2 && <CustomCarousel {...hubspotFieldsCarousel2} />} */}
        {hubspotFieldsCardSection3 && <CategoryList {...hubspotFieldsCardSection3} />}
        {hubspotFieldsCardSection4 && <CategoryList {...hubspotFieldsCardSection4} />}
        {hubspotFieldsCarousel3 && <CustomCarousel {...hubspotFieldsCarousel3} />}
        {hubspotFieldsCarousel4 && <CustomCarousel {...hubspotFieldsCarousel4} />}
        {hubspotFieldsCardSection5 && <CategoryList {...hubspotFieldsCardSection5} />}
        {hubspotFieldsCarousel5 && <CustomCarousel {...hubspotFieldsCarousel5} />}
        <PromotionHomePageBanner />
        {hubspotFieldsRequestProduct && <RequestProduct {...hubspotFieldsRequestProduct} />}
        
      </main>
    </>
  );
}
