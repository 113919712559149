import React, { useContext } from "react";
import styles from "./styles.module.scss";
import Image from "next/image";
import Button from "@components/shared/Button";
import { PromotionHomeBannerProps } from "./types";
import { AppContext } from "@src/libs/context";
import classnames from "classnames";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import HaggleModal from "../HaggleModal/HaggleModal";
import axios from "axios";
import { Modal } from "antd";

const PromotionHomePageBanner = () => {
  const { guestUser } = useContext<any>(AppContext);
  const [isGuestUser, setIsGuestUser] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isSignUpModalVisible, setIsSignUpModalVisible] = useState(false);
  const router = useRouter();
  const isHagglePage = router?.asPath === "/haggle";
  const [hubstpotFields, setHubstpotFields] = useState<PromotionHomeBannerProps>();

  const showSignUpModal = () => {
    setIsSignUpModalVisible(true);
  };

  const handleSignUpModalCancel = () => {
    setIsSignUpModalVisible(false);
  };

  useEffect(() => {
    setIsGuestUser(guestUser);
  }, [guestUser]);

  useEffect(() => {
    const fetchHubspotData = async () => {
      try {
        const { data } = await axios.get("/api/hubspot-cms/homePromotionBanner-cms"); // Ensure this matches your API route
        setHubstpotFields(data.fieldPlaceholders);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error("Error fetching HubSpot banner:", error.message);
        } else {
          console.error("Error fetching HubSpot banner:", error);
        }
      }

    };

    fetchHubspotData();
  }, []);

  if (isGuestUser && hubstpotFields?.AllowedUsers === "Registered") return <></>;
  if (!isGuestUser && hubstpotFields?.AllowedUsers === "Guest") return <></>;


  return (
    <div
      className={classnames("container bg-white py-40", styles.mainContainer,isHagglePage ? styles.hagglePage :"")}
    >
      <div
        style={{ backgroundColor: hubstpotFields?.backgroundColor }}
        className={classnames("py-14", styles.bannerContainer)}
      >
        <div className={styles.imgSection}>
          <div className={classnames(isHagglePage ? styles.hagglePage :"", styles.promoImage)}>
            <Image
              unoptimized
              src={ hubstpotFields ? hubstpotFields.image?.url : ""}
              alt={hubstpotFields? hubstpotFields.image?.altText : ""}
              width={isHagglePage ? 453 : 305}
              height={isHagglePage ? 304 :276}
            />
          </div>
        </div>
        <div className={`py-10 ${styles.descBtnContainer}`}>
          <div
            className={classnames(styles.promoDescriptionContainer, "ms-25")}
          >
            <div
              className={classnames(
                styles.titleWrapper,
                "text-oxfordBlue font-bolder-h4 pb-4"
              )}
            >
              {hubstpotFields?.title}
            </div>
            <div
              className={classnames(
                styles.promoDescription,
                "text-oxfordBlue font-bold-sm lh-sm"
              )}
            >
              {hubstpotFields?.description}
            </div>
          </div>
          <div className={styles.shopButtonWrapper}>
            <Button
              theme="primary"
              className={classnames(
                styles.btn,
                "font-bold-base bg-primary m-5"
              )}
              onClick={(event: any) => {
                event.preventDefault();
                if (router?.asPath === "/haggle") {
                  setOpenModal(true);
                } else {
                  showSignUpModal();
                }
              }}
              href={router?.asPath === "/haggle" ? "" : hubstpotFields?.buttonLink}
            >
              {hubstpotFields?.buttonText}
            </Button>
          </div>

          <HaggleModal isModalOpen={openModal} setIsModalOpen={setOpenModal} />
        </div>
      </div>
      <Modal
        open={isSignUpModalVisible}
        onCancel={handleSignUpModalCancel}
        footer={null}
        width="40%"
        style={{ top: 20 }}
        bodyStyle={{ overflow: "auto", maxHeight: "70vh", display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <iframe
          src="https://share.hsforms.com/1qGdz9GjYTf-3jraATRlbkwdasgk"
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="yes"
          style={{ minHeight: "500px", height: "70vh", width: "100%" }}
        ></iframe>
      </Modal>
    </div>
  );
};
export default PromotionHomePageBanner;